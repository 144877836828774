











































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import OpAppDownloadPanel from '@/components/OpAppDownloadPanel.vue';

@Component({
  components: {
    OpAppDownloadPanel
  }
})
export default class P0916 extends Vue {}
